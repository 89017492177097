import Button from '$components/buttons/button.react';
import CardHeader from '$components/cards/card-header/card-header.react';
import Card from '$components/cards/card/card.react';
import GridCell from '$components/grid/react-grid/cell/grid-cell';
import Grid, { IReactGridColumn } from '$components/grid/react-grid/grid.react';
import { UseGridDataReturnType } from '$components/grid/react-grid/gridhelper';
import { downloadFileFromUrl } from '$lib/hooks/blob-utillities';
import { formatDate } from '$lib/dateHelpers';
import {
  DailyDoseReportDownloadDocument,
  DailyDoseReportQueryQuery,
  DailyDoseReportDownloadQuery,
  DailyDoseReportDownloadQueryVariables,
  DailyDoseReportQueryQueryVariables,
  ScheduledReportFilter,
  SortDirection
} from '$typings/graphql-codegen';
import { useCaseInsensitiveTranslation } from "$lib/hooks/case-insensitive-translation";
import React, { FC, useState } from 'react';
import { runSingleQuery } from '$pages/common/GraphQLFetcher';
import { makeSortedData } from '../../../makeSortedData';

type DailyDoseSortValues = {
    sortColumnKey: "siteAlias" | "doseDate" | "doseValue" | "doseUnit",
    sortDirection: SortDirection;
}

type ReportData = DailyDoseReportQueryQuery["reports"]["dailyDose"]["data"][0]

const dailyDoseColumns: IReactGridColumn<ReportData>[] = [
  {
    columnTitle: 'UI_Reports_DailyDose_Table_Header_SiteAlias',
    render: (row, width) => (
      <GridCell
        width={width}
        className="link clickable"
        columnLink={`/sitedetails/${row.siteId}`}
      >
        {row.siteAlias.trim()}
      </GridCell>
    ),
    columnKey: 'siteAlias',
    initialWidth: 300
  },
  {
    columnTitle: 'UI_Reports_DailyDose_Table_Header_ChannelAlias',
    render: (row, width) => <GridCell width={width}>{row.channelAlias}</GridCell>,
    columnKey: 'channelAlias',
    initialWidth: 150
  },
  {
    columnTitle: 'UI_Reports_DailyDose_Table_Header_Buid',
    render: (row, width) => <GridCell width={width}>{row.buid}</GridCell>,
    columnKey: 'buid',
    initialWidth: 150
  },
  {
    columnTitle: 'UI_Reports_DailyDose_Table_Header_AccessTag',
    render: (row, width) => <GridCell width={width}>{row.accessTag}</GridCell>,
    columnKey: 'accessTag',
    initialWidth: 150
  },
  {
    columnTitle: 'UI_Reports_DailyDose_Table_Header_DoseDate',
    render: (row, width) => <GridCell width={width}>{formatDate(row.doseDate || undefined, false, '.')}</GridCell>,
    columnKey: 'doseDate',
    initialWidth: 150
  },
  {
    columnTitle: 'UI_Reports_DailyDose_Table_Header_DoseValue',
    render: (row, width) => <GridCell width={width}>{row.doseValue}</GridCell>,
    columnKey: 'doseValue',
    initialWidth: 150
  },
  {
    columnTitle: 'UI_Reports_DailyDose_Table_Header_DoseUnit',
    render: (row, width) => <GridCell width={width}>{row.doseUnit}</GridCell>,
    columnKey: 'doseUnit',
    initialWidth: 150
  }
];

interface IDailyDoseReportGridProps {
  variables?: DailyDoseReportQueryQueryVariables;
  data: UseGridDataReturnType<ReportData>;
  didChangeFilters: boolean;
}

const download = async (reportFilter: ScheduledReportFilter) => {
  const variables: DailyDoseReportDownloadQueryVariables = {
    input: reportFilter
  };  

  try {
    const { promise } = runSingleQuery<DailyDoseReportDownloadQuery, DailyDoseReportDownloadQueryVariables>(DailyDoseReportDownloadDocument, variables);
    const reponse = await promise;
    
    await downloadFileFromUrl(
      reponse.reports.dailyDose.blobUrl,
      'DailyDoseReport.xlsx');
  } catch (error) {
    throw new Error('Could not download report');
  }
};


// Daily Dose Grid
const DailyDoseReportGrid: FC<IDailyDoseReportGridProps> = ({
  variables,
  data
}) => {
  
  const [dailyDoseSortValues, dailyDoseSetSortValues] = useState<DailyDoseSortValues>({
    sortColumnKey: "siteAlias",
    sortDirection: SortDirection.Asc
  });

  const dailyDoseOrderedData = {
    ...data,
    data: data?.data ? makeSortedData(data.data, dailyDoseSortValues.sortDirection, dailyDoseSortValues.sortColumnKey) : undefined
  };

  const [t] = useCaseInsensitiveTranslation();

  return (
    <Card className="flex flex_1 mar_tm mar_rm mar_bm">
      <CardHeader
        action={
          <Button
            variant="secondary"
            className="download-reports"
            disabled={!variables}
            onClick={() => variables && download(variables.input)}
            icon="fa-download"
          >
            {t('ui_common_download')}
          </Button>
        }
      >
      {t('UI_Reports_DailyDose')}
      </CardHeader>
      
      <Grid
        name="daiy_dose_grid"
        items={dailyDoseOrderedData}
        loading={data?.isRevalidating && !data?.data}
        columns={dailyDoseColumns}
        sortedColumnKey={dailyDoseSortValues.sortColumnKey}
        sortedDirection={dailyDoseSortValues?.sortDirection}
        sortChanged={(sortByColumn, sortDirection) =>
          dailyDoseSetSortValues({
            sortColumnKey: sortByColumn,
            sortDirection: sortDirection
          })
        }
      />
    </Card>
  );
};

export default DailyDoseReportGrid;
