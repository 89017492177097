import { Reports, DailyDoseReportQueryQueryVariables, DailyDoseReportQueryDocument } from '$typings/graphql-codegen';
import React, { FC, useState } from 'react';
import ScheduledReportFilter from '../modules/schedule-report-filters/scheduled-report-filter.react';
import DailyDoseReportGrid from './modules/daily-dose-report-grid/daily-dose-report-grid';
import { useGridData } from '$components/grid/react-grid/gridhelper';

export interface IDailyDoseReportReportProps {
  scheduledReportId?: string;
}

const DailyDoseReport: FC<IDailyDoseReportReportProps> = ({
  scheduledReportId
}) => {
  const [variables, setVariables] = useState<
  DailyDoseReportQueryQueryVariables
  >();
  const [didChangeFilters, setDidChangeFilters] = useState<boolean>(false);

  const dailyDoseData = useGridData(
    DailyDoseReportQueryDocument,
    variables,
    t => t.reports.dailyDose.data.length || 0,
    t => t.reports.dailyDose.data
  );

  return (
    <div className="daily-dose-report flex ai-stretch fill-height">
      <ScheduledReportFilter
        scheduledReportId={scheduledReportId}
        allowRunningUnfiltered={false}
        report={Reports.DailyDose}
        reportIsRunning={dailyDoseData.isRevalidating}
        filtersChanged={input => { 
          if (!didChangeFilters) {
            setDidChangeFilters(true);
          }
          setVariables({ input });
        }}     
      />
      <DailyDoseReportGrid variables={variables} didChangeFilters={didChangeFilters} data={dailyDoseData} />
    </div>
  );
};

export default DailyDoseReport;
