import DailyDoseReport, { IDailyDoseReportReportProps } from './daily-dose-report';
import { ReactWrapper } from '$pages/common/react-wrapper';

interface IDailyDoseReportArguments {
  scheduledReportId?: string;
}

export class DailyDoseReportPage extends ReactWrapper<
  IDailyDoseReportReportProps,
  IDailyDoseReportArguments
> {
  constructor() {
    super(DailyDoseReport, (params) => ({
      scheduledReportId: params.scheduledReportId,
    }));
  }
}
